<template>
  <div
    v-if="applicationStatusInfo.title"
    v-expose="{ id: '1-20-1-95', data: { return_front_type: applicationStatusInfo.type } }"
    v-tap="{ id: '1-20-1-96', data: { order_no: order.billno, return_front_type: applicationStatusInfo.type } }"
    class="order-list_warp"
    @click.stop="jumpToDetail"
  >
    <div class="order-list_application">
      <div class="application-status_icon">
        <img
          :src="applicationStatusInfo.img"
          :class="{ 'error_img': applicationStatusInfo.type == 7 }"
          alt=""
        />
      </div>
      <div class="application-status_ctn">
        <div
          v-expose="applicationStatusInfo.reportExpose ?? {}"
          :class="['status-ctn_title', { 'error-ctn_title': applicationStatusInfo.type == 7 }]"
        >
          {{ applicationStatusInfo.title }}
        </div>
        <div
          v-if="!!applicationStatusInfo.desc"
          ref="statusCtnDesc"
          class="status-ctn_desc"
        >
          <div
            ref="statusCtnInfo"
            class="status-ctn_info"
          >
            {{ applicationStatusInfo.desc }}
            <span
              v-if="showLess"
              class="status-ctn_less"
              @click.stop="showMoreEvt"
            >
              {{ language.SHEIN_KEY_PWA_28641 }}
            </span>
          </div>
          <div
            v-if="showMore"
            class="status-ctn_more"
            @click.stop="hideMoreEvt"
          >
            ...{{ language.SHEIN_KEY_PWA_23768 }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="applicationStatusInfo.agingText"
      class="status-ctn_agingText"
    >
      {{ applicationStatusInfo.agingText }}
    </div>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isFunction } from '@shein/common-function'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
daEventCenter.addSubscriber({ modulecode: '1-20-1' })

export default {
  directives: {
    tap,
    expose
  }
}
</script>
<script setup>
import { defineProps, computed, ref, nextTick, onMounted } from 'vue'
import { template as htmlTemplate } from '@shein/common-function'

let { langPath, HAPPY_RETURN_CHANNEL } = gbCommonInfo
let props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  order: {
    type: Object,
    default: () => {}
  },
  waitSendReturnOrder: {
    type: Array,
    default: () => []
  },
})
let imageMap = {
  1: 'https://img.ltwebstatic.com/images3_acp/2023/11/21/47/17005754910e592de02580e1023ba5e164e2e6d13b.png',
  2: 'https://img.shein.com/images3/2023/12/19/8d/1702973006dd85562e574979242947bd9dfca5d1dd.png',
  3: 'https://img.ltwebstatic.com/images3_ccc/2024/06/12/d2/1718192134dfc72b88a5453c8b53adb573598b59c8.png'
}

let showMore = ref(false)
let showLess = ref(false)
let statusCtnDesc = ref(null)
let statusCtnInfo = ref(null)

let applicationStatusInfo = computed(() => {
  let {
    SHEIN_KEY_PWA_27603,
    SHEIN_KEY_PWA_27607,
    SHEIN_KEY_PWA_27611,
    SHEIN_KEY_PWA_27610,
    SHEIN_KEY_PWA_27615,
    SHEIN_KEY_PWA_27614,
    SHEIN_KEY_PWA_27601,
    SHEIN_KEY_PWA_27612,
    SHEIN_KEY_PWA_25506,
    SHEIN_KEY_PWA_27613,
    SHEIN_KEY_PWA_25010,
    SHEIN_KEY_PWA_27602,
    SHEIN_KEY_PWA_27616,
    SHEIN_KEY_PWA_27608,
    SHEIN_KEY_PWA_17030,
    SHEIN_KEY_PWA_25948,
    SHEIN_KEY_PWA_29825,
    SHEIN_KEY_PWA_31685,
    SHEIN_KEY_PWA_31622
  } = props?.language || {}
  let {
    billno,
    returnStatusInfo = {}
  } = props?.order || {}
  let {
    refund_order_list = [],
    return_order_list = []
  } = returnStatusInfo || {}

  if (props.waitSendReturnOrder?.length) {
    let {
      billno,
    } = props?.order || {}
    const waitSentCurrentOrder = props.waitSendReturnOrder.filter(item => item?.order_no === billno)

    if (waitSentCurrentOrder.length) {
      const uniqueList = new Set(waitSentCurrentOrder.map(i => i.union_no))

      return {
        img: imageMap[3],
        title: SHEIN_KEY_PWA_31685,
        desc: htmlTemplate(uniqueList.size, SHEIN_KEY_PWA_31622),
        agingText: '',
        jumpLink: `${langPath}/orders/returnPackageList?billno=${billno}`,
        type: 8,
        reportExpose: {
          id: '1-20-1-133',
        },
        reportCb: () => {
          daEventCenter.triggerNotice({
            daId: '1-20-1-132',
            bindData: { show_type: 2 }
          })
        }
      }
    }
  }

  let errorRefundOrderList = refund_order_list?.filter(item => item.show_payment)
  let successReturnOrderList = return_order_list?.filter(item => item.return_order_status == '0' && [1, 2, 3, 12, 13, 14, 15]?.includes(+item.shipping_status))
  let errorReturnOrderList = return_order_list?.filter(item => item.return_order_status == '0' && item.shipping_status == 11)
  let trackReturnOrderList = return_order_list?.filter(item => item.return_method != 4 && ![4, 5, 6]?.includes(+item.return_order_status) && ![1, 2, 3, 11, 12, 13]?.includes(+item.shipping_status) && !item.arrive_time)
  let receivingReturnOrderList = return_order_list?.filter(item => item.defective_return)
  let refundingRefundOrderList = refund_order_list?.filter(item => [0, 1, 2]?.includes(+item.refund_status))
  let successRefundOrderList = refund_order_list?.filter(item => item.refund_status == '3')

  if (errorRefundOrderList?.length > 0) {
    errorRefundOrderList = errorRefundOrderList?.sort((prev, next) => next.refund_add_time - prev.refund_add_time)
    let { refund_bill_id } = errorRefundOrderList?.[0] || {}
    return {
      img: imageMap[2],
      title: SHEIN_KEY_PWA_27603,
      desc: SHEIN_KEY_PWA_27607,
      agingText: '',
      jumpLink: `${langPath}/orders/refundLabel/${billno}?refund_bill_id=${refund_bill_id}`,
      type: 7
    }
  }

  if (successReturnOrderList?.length > 0) {
    successReturnOrderList = successReturnOrderList?.sort((prev, next) => next.order_datetime - prev.order_datetime)
    let { return_method, return_and_refund_aging, return_order_no } = successReturnOrderList?.[0] || {}
    return {
      img: imageMap[1],
      title: SHEIN_KEY_PWA_27611,
      desc: return_method != 4 ? SHEIN_KEY_PWA_27610 : SHEIN_KEY_PWA_27615,
      agingText: return_and_refund_aging ? htmlTemplate(return_and_refund_aging, SHEIN_KEY_PWA_27614) : '',
      jumpLink: `${langPath}/orders/returnLabelNew/${billno}?return_order_id=${return_order_no}`,
      type: 1
    }
  }

  if (errorReturnOrderList?.length > 0) {
    errorReturnOrderList = errorReturnOrderList?.sort((prev, next) => next.order_datetime - prev.order_datetime)
    let { return_and_refund_aging, return_order_no } = errorReturnOrderList?.[0] || {}
    return {
      img: imageMap[1],
      title: SHEIN_KEY_PWA_27612,
      desc: SHEIN_KEY_PWA_27601,
      agingText: return_and_refund_aging ? htmlTemplate(return_and_refund_aging, SHEIN_KEY_PWA_27614) : '',
      jumpLink: `${langPath}/orders/returnLabelNew/${billno}?return_order_id=${return_order_no}`,
      type: 2
    }
  }

  if (trackReturnOrderList?.length > 0) {
    trackReturnOrderList = trackReturnOrderList?.sort((prev, next) => next.order_datetime - prev.order_datetime)
    let { trackList = [], return_order_no, return_and_refund_aging } = trackReturnOrderList?.[0] || {}
    let desc = trackList?.[0]?.details ? trackList?.[0]?.details : SHEIN_KEY_PWA_25506
    if (
      Array.isArray(trackList) &&
      trackList.length &&
      Array.isArray(return_order_list) &&
      return_order_list.some(i => HAPPY_RETURN_CHANNEL?.includes(i.shipping_type))
    ) {
      desc = SHEIN_KEY_PWA_29825
    }
    return {
      img: trackList?.[0]?.logistics_pic_url ? trackList?.[0]?.logistics_pic_url : imageMap[1],
      title: SHEIN_KEY_PWA_27613,
      desc: desc,
      agingText: return_and_refund_aging ? htmlTemplate(return_and_refund_aging, SHEIN_KEY_PWA_27614) : '',
      jumpLink: `${langPath}/orders/returnLabelNew/${billno}?return_order_id=${return_order_no}`,
      type: 3
    }
  }

  if (receivingReturnOrderList?.length > 0) {
    receivingReturnOrderList = receivingReturnOrderList?.sort((prev, next) => next.order_datetime - prev.order_datetime)
    let { return_order_no } = receivingReturnOrderList?.[0] || {}
    return {
      img: imageMap[1],
      title: SHEIN_KEY_PWA_27602,
      desc: SHEIN_KEY_PWA_25010,
      agingText: '',
      jumpLink: `${langPath}/orders/returnLabelNew/${billno}?return_order_id=${return_order_no}`,
      type: 4
    }
  }

  if (refundingRefundOrderList?.length > 0) {
    refundingRefundOrderList = refundingRefundOrderList?.sort((prev, next) => next.refund_add_time - prev.refund_add_time)
    let { refund_aging, refund_bill_id } = refundingRefundOrderList?.[0] || {}
    return {
      img: imageMap[1],
      title: SHEIN_KEY_PWA_17030,
      desc: SHEIN_KEY_PWA_25948,
      agingText: refund_aging ? htmlTemplate(refund_aging, SHEIN_KEY_PWA_27614) : '',
      jumpLink: `${langPath}/orders/refundLabel/${billno}?refund_bill_id=${refund_bill_id}`,
      type: 5
    }
  }

  if (successRefundOrderList?.length > 0) {
    successRefundOrderList = successRefundOrderList?.sort((prev, next) => next.refund_add_time - prev.refund_add_time)
    let { refund_bill_id } = successRefundOrderList?.[0] || {}
    return {
      img: imageMap[1],
      title: SHEIN_KEY_PWA_27616,
      desc: SHEIN_KEY_PWA_27608,
      agingText: '',
      jumpLink: `${langPath}/orders/refundLabel/${billno}?refund_bill_id=${refund_bill_id}`,
      type: 6
    }
  }

  return {
    img: '',
    title: '',
    desc: '',
    agingText: '',
    jumpLink: ''
  
  }
})

let jumpToDetail = () => {
  const reportCb = applicationStatusInfo?.value?.reportCb
  if (isFunction(reportCb)) {
    reportCb()
  }
  location.href = applicationStatusInfo?.value?.jumpLink
}

let hideMoreEvt = () => {
  statusCtnDesc.value.style.maxHeight = 'inherit'
  showMore.value = false
  showLess.value = true
}

let showMoreEvt = () => {
  statusCtnDesc.value.style.maxHeight = '34px'
  showMore.value = true
  showLess.value = false
}

onMounted(async () => {
  await nextTick()
  showMore.value = statusCtnDesc?.value?.clientHeight < statusCtnInfo?.value?.clientHeight
  showLess.value = false
})
</script>

<style lang="less" scoped>
.order-list_warp{
  margin: 0 .32rem 20/75rem .32rem;
  padding: 20/75rem;
  background-color: rgba(248, 248, 248, 0.75);
}
.order-list_application{
  display: flex;
}
.application-status_icon{
  .margin-r(12/75rem);
  img {
    width: 40/75rem;
    height: 42.5/75rem;
    display: inline-block;
    border: none;
    outline: none;
    object-fit: contain;
    &.error_img{
      width: 40/75rem;
      height: 40/75rem;
    }
  }
}
.application-status_ctn{
  flex: 1;
  .txt-l();
  .status-ctn_title{
    .font-dpr(24px);
    font-weight: 600;
    color: var(--sui-color-gray-dark-1, #000);
    word-break: break-word;
    &.error-ctn_title{
      color: #BF4123;
    }
  }
  .status-ctn_desc{
    margin-top: 8/75rem;
    .font-dpr(24px);
    font-weight: 400;
    color: var(--sui-color-gray-dark-1, #000);
    word-break: break-word;
    max-height: 34px;
    overflow: hidden;
    position: relative;
  }
  .status-ctn_info{
    line-height: 17px;
  }
  .status-ctn_more{
    position: absolute;
    bottom: 0;
    .right(0);
    padding: 0 8/75rem;
    line-height: 17px;
    background: #F6F6F6;
    color: var(--sui-color-link, #2D68A8);
    text-transform: capitalize;
  }
  .status-ctn_less{
    padding: 0 8/75rem;
    line-height: 17px;
    background: #F6F6F6;
    color: var(--sui-color-link, #2D68A8);
    text-transform: capitalize;
  }
}
.status-ctn_agingText{
  margin-top: 16/75rem;
  .font-dpr(24px);
  font-weight: 400;
  color: var(--sui-color-gray-light-1, #959595);
  word-break: break-word;
}
</style>
